'use strict';

angular.module('cpformplastApp.machines')
  .config(function ($stateProvider) {
    $stateProvider
      .state('machines', {
        url: '/machines',
        templateUrl: 'app/machines/machines.html',
        controller: 'machinesController',
        authenticate: ['admin','management_dept','receptionist']
      })
      .state('machines/machine-creation', {
        url: '/machines/:machineId',
        templateUrl: 'app/machines/machine-creation/machine-creation.html',
        controller: 'machineCreationController',
        authenticate: ['admin','management_dept','receptionist']
      });
  });
